<script>
import { ArrowUpIcon, UserIcon, MailIcon, KeyIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";

/**
 * index-enterprise component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    UserIcon,
    MailIcon,
    KeyIcon,
    Carousel,
    Slide,
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <!-- Hero Start -->
    <section
      class="bg-half-260 bg-primary d-table w-100"
      style="background: url('images/enterprise.png') center center"
    >
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="title-heading text-center mt-4">
              <h1 class="display-4 title-dark font-weight-bold text-white mb-3">
                Starting in Strong Way
              </h1>
              <p class="para-desc mx-auto text-white-50">
                Launch your campaign and benefit from our expertise on designing
                and managing conversion centered bootstrap4 html page.
              </p>
              <div class="mt-4 pt-2">
                <a href="javascript: void(0);" class="btn btn-primary"
                  ><i class="mdi mdi-email"></i> Get Started</a
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Feature Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">What We Do ?</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-md-4 col-12 mt-5">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <img
                  src="/images/icon/pen.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Design & Development</h4>
                <p class="text-muted mb-0">
                  Nisi aenean vulputate eleifend tellus vitae eleifend enim a
                  Aliquam aenean elementum semper.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <img
                  src="/images///icon/video.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Management & Marketing</h4>
                <p class="text-muted mb-0">
                  Allegedly, a Latin scholar established the origin of the text
                  by established compiling unusual word.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <img
                  src="/images///icon/intellectual.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Stratagy & Research</h4>
                <p class="text-muted mb-0">
                  It seems that only fragments of the original text remain in
                  the Lorem the original Ipsum texts used today.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <img
                  src="/images///icon/user.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Easy To Use</h4>
                <p class="text-muted mb-0">
                  Nisi aenean vulputate eleifend tellus vitae eleifend enim a
                  Aliquam aenean elementum semper.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <img
                  src="/images///icon/calendar.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Daily Reports</h4>
                <p class="text-muted mb-0">
                  Allegedly, a Latin scholar established the origin of the text
                  by established compiling unusual word.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 col-12 mt-5">
            <div class="features text-center">
              <div class="image position-relative d-inline-block">
                <img
                  src="/images///icon/sand-clock.svg"
                  class="avatar avatar-small"
                  alt=""
                />
              </div>

              <div class="content mt-4">
                <h4 class="title-2">Real Time Zone</h4>
                <p class="text-muted mb-0">
                  It seems that only fragments of the original text remain in
                  the Lorem Ipsum texts the original used today.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div
            class="
              col-lg-7 col-md-6
              order-2 order-md-1
              mt-4 mt-sm-0
              pt-2 pt-sm-0
            "
          >
            <div class="section-title">
              <h4 class="title mb-4">
                Speed up your development <br />
                with <span class="text-primary">Landrick.</span>
              </h4>
              <p class="text-muted">
                Using Landrick to build your site means never worrying about
                designing another page or cross browser compatibility. Our
                ever-growing library of components and pre-designed layouts will
                make your life easier.
              </p>
              <ul class="list-unstyled text-muted">
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Digital Marketing Solutions for Tomorrow
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Our Talented & Experienced Marketing Agency
                </li>
                <li class="mb-0">
                  <span class="text-primary h5 mr-2"
                    ><i class="uim uim-check-circle"></i></span
                  >Create your own skin to match your brand
                </li>
              </ul>
              <a href="javascript: void(0);" class="mt-3 h6 text-primary"
                >Find Out More <i class="mdi mdi-chevron-right"></i
              ></a>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-5 col-md-6 order-1 order-md-2">
            <div class="card rounded border-0 shadow ml-lg-5">
              <div class="card-body">
                <img
                  src="/images///illustrator/Mobile_notification_SVG.svg"
                  alt=""
                />

                <div class="content mt-4 pt-2">
                  <form>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group position-relative">
                          <label
                            >Name : <span class="text-danger">*</span></label
                          >
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input
                            type="text"
                            class="form-control pl-5"
                            placeholder="Name"
                            name="name"
                            required=""
                          />
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12">
                        <div class="form-group position-relative">
                          <label
                            >Email : <span class="text-danger">*</span></label
                          >
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input
                            type="email"
                            class="form-control pl-5"
                            placeholder="Email"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12">
                        <div class="form-group position-relative">
                          <label
                            >Password :
                            <span class="text-danger">*</span></label
                          >
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input
                            type="password"
                            class="form-control pl-5"
                            placeholder="Password"
                            required=""
                          />
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-lg-12 mt-2 mb-0">
                        <button class="btn btn-primary btn-block">
                          Download
                        </button>
                      </div>
                      <!--end col-->
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Feature End -->

    <!-- Testi Start -->
    <section
      class="section"
      style="background: url('images/3.jpg') center center"
    >
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row py-5 justify-content-center">
          <div class="col-lg-8 text-center">
            <carousel
              id="owl-fade"
              class="owl-carousel owl-theme"
              dir="ltr"
              :per-page="1"
            >
              <Slide>
                <div class="customer-testi text-center mx-4">
                  <img
                    src="/images///client/01.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <p
                    class="
                      text-light
                      para-dark
                      h5
                      font-weight-normal font-italic
                      mt-4
                    "
                  >
                    " It seems that only fragments of the original text remain
                    in the Lorem Ipsum texts used today. The most well-known
                    dummy text is the 'Lorem Ipsum', which is said to have
                    originated in the 16th century. "
                  </p>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-light title-dark">Thomas Israel</h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center mx-4">
                  <img
                    src="/images///client/02.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <p
                    class="
                      text-light
                      para-dark
                      h5
                      font-weight-normal font-italic
                      mt-4
                    "
                  >
                    " The advantage of its Latin origin and the relative
                    meaninglessness of Lorum Ipsum is that the text does not
                    attract attention to itself or distract the viewer's
                    attention from the layout. "
                  </p>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-light title-dark">Carl Oliver</h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center mx-4">
                  <img
                    src="/images///client/03.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <p
                    class="
                      text-light
                      para-dark
                      h5
                      font-weight-normal font-italic
                      mt-4
                    "
                  >
                    " There is now an abundance of readable dummy texts. These
                    are usually used when a text is required purely to fill a
                    space. These alternatives to the classic Lorem Ipsum texts
                    are often amusing and tell short, funny or nonsensical
                    stories. "
                  </p>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-light title-dark">Barbara McIntosh</h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center mx-4">
                  <img
                    src="/images///client/04.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <p
                    class="
                      text-light
                      para-dark
                      h5
                      font-weight-normal font-italic
                      mt-4
                    "
                  >
                    " According to most sources, Lorum Ipsum can be traced back
                    to a text composed by Cicero in 45 BC. Allegedly, a Latin
                    scholar established the origin of the text by compiling all
                    the instances of the unusual word 'consectetur' he could
                    find "
                  </p>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-light title-dark">Christa Smith</h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center mx-4">
                  <img
                    src="/images///client/05.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <p
                    class="
                      text-light
                      para-dark
                      h5
                      font-weight-normal font-italic
                      mt-4
                    "
                  >
                    " It seems that only fragments of the original text remain
                    in the Lorem Ipsum texts used today. The most well-known
                    dummy text is the 'Lorem Ipsum', which is said to have
                    originated in the 16th century. "
                  </p>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-light title-dark">Dean Tolle</h6>
                </div>
                <!--end customer testi-->
              </Slide>
              <Slide>
                <div class="customer-testi text-center mx-4">
                  <img
                    src="/images///client/06.jpg"
                    class="
                      img-fluid
                      avatar avatar-small
                      rounded-circle
                      mx-auto
                      shadow
                    "
                    alt=""
                  />
                  <p
                    class="
                      text-light
                      para-dark
                      h5
                      font-weight-normal font-italic
                      mt-4
                    "
                  >
                    " It seems that only fragments of the original text remain
                    in the Lorem Ipsum texts used today. One may speculate that
                    over the course of time certain letters were added or
                    deleted at various positions within the text. "
                  </p>
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                    <li class="list-inline-item">
                      <i class="mdi mdi-star text-warning"></i>
                    </li>
                  </ul>
                  <h6 class="text-light title-dark">Jill Webb</h6>
                </div>
                <!--end customer testi-->
              </Slide>
            </carousel>
            <!--end owl carousel-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Testi End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Price start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Charge your creative inspiration</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                business-rate
                shadow
                bg-light
                border-0
                rounded
              "
            >
              <div class="card-body">
                <h2 class="title text-uppercase mb-4">Free</h2>
                <div class="d-flex mb-4">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">0</span>
                  <span class="h4 align-self-end mb-1">/mo</span>
                </div>

                <ul class="list-unstyled mb-0 pl-0">
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Full Access
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Source Files
                  </li>
                </ul>
                <a href="javascript: void(0);" class="btn btn-primary mt-4"
                  >Buy Now</a
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                business-rate
                shadow
                bg-white
                border-0
                rounded
              "
            >
              <div class="card-body">
                <h2 class="title text-uppercase text-primary mb-4">Starter</h2>
                <div class="d-flex mb-4">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">39</span>
                  <span class="h4 align-self-end mb-1">/mo</span>
                </div>

                <ul class="list-unstyled mb-0 pl-0">
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Full Access
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Source Files
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Free Appointments
                  </li>
                </ul>
                <a href="javascript: void(0);" class="btn btn-primary mt-4"
                  >Get Started</a
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                business-rate
                shadow
                bg-light
                border-0
                rounded
              "
            >
              <div class="card-body">
                <h2 class="title text-uppercase mb-4">Professional</h2>
                <div class="d-flex mb-4">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">59</span>
                  <span class="h4 align-self-end mb-1">/mo</span>
                </div>

                <ul class="list-unstyled mb-0 pl-0">
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Full Access
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Source Files
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >1 Domain Free
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Enhanced Security
                  </li>
                </ul>
                <a href="javascript: void(0);" class="btn btn-primary mt-4"
                  >Try It Now</a
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                business-rate
                shadow
                bg-light
                border-0
                rounded
              "
            >
              <div class="card-body">
                <h2 class="title text-uppercase mb-4">Ultimate</h2>
                <div class="d-flex mb-4">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">79</span>
                  <span class="h4 align-self-end mb-1">/mo</span>
                </div>

                <ul class="list-unstyled mb-0 pl-0">
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Full Access
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Enhanced Security
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Source Files
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >1 Domain Free
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Free Installment
                  </li>
                </ul>
                <a href="javascript: void(0);" class="btn btn-primary mt-4"
                  >Started Now</a
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Latest News</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="/images///blog/01.jpg"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript: void(0);"
                    class="card-title title text-dark"
                    >Design your apps in your own way</a
                  >
                </h5>
                <div class="post-meta d-flex justify-content-between mt-3">
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item mr-2 mb-0">
                      <a href="javascript: void(0);" class="text-muted like"
                        ><i class="mdi mdi-heart-outline mr-1"></i>33</a
                      >
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" class="text-muted comments"
                        ><i class="mdi mdi-comment-outline mr-1"></i>08</a
                      >
                    </li>
                  </ul>
                  <router-link
                    to="/page-blog-detail"
                    class="text-muted readmore"
                    >Read More <i class="mdi mdi-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="author">
                <small class="text-light user d-block"
                  ><i class="mdi mdi-account"></i> Calvin Carlo</small
                >
                <small class="text-light date"
                  ><i class="mdi mdi-calendar-check"></i> 13th August,
                  2019</small
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="/images///blog/02.jpg"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript: void(0);"
                    class="card-title title text-dark"
                    >How apps is changing the IT world</a
                  >
                </h5>
                <div class="post-meta d-flex justify-content-between mt-3">
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item mr-2 mb-0">
                      <a href="javascript: void(0);" class="text-muted like"
                        ><i class="mdi mdi-heart-outline mr-1"></i>33</a
                      >
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" class="text-muted comments"
                        ><i class="mdi mdi-comment-outline mr-1"></i>08</a
                      >
                    </li>
                  </ul>
                  <router-link
                    to="/page-blog-detail"
                    class="text-muted readmore"
                    >Read More <i class="mdi mdi-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="author">
                <small class="text-light user d-block"
                  ><i class="mdi mdi-account"></i> Calvin Carlo</small
                >
                <small class="text-light date"
                  ><i class="mdi mdi-calendar-check"></i> 13th August,
                  2019</small
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 mt-4 pt-2">
            <div class="card blog rounded border-0 shadow">
              <div class="position-relative">
                <img
                  src="/images///blog/03.jpg"
                  class="card-img-top rounded-top"
                  alt="..."
                />
                <div class="overlay rounded-top bg-dark"></div>
              </div>
              <div class="card-body content">
                <h5>
                  <a
                    href="javascript: void(0);"
                    class="card-title title text-dark"
                    >Smartest Applications for Business</a
                  >
                </h5>
                <div class="post-meta d-flex justify-content-between mt-3">
                  <ul class="list-unstyled mb-0">
                    <li class="list-inline-item mr-2 mb-0">
                      <a href="javascript: void(0);" class="text-muted like"
                        ><i class="mdi mdi-heart-outline mr-1"></i>33</a
                      >
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" class="text-muted comments"
                        ><i class="mdi mdi-comment-outline mr-1"></i>08</a
                      >
                    </li>
                  </ul>
                  <router-link
                    to="/page-blog-detail"
                    class="text-muted readmore"
                    >Read More <i class="mdi mdi-chevron-right"></i
                  ></router-link>
                </div>
              </div>
              <div class="author">
                <small class="text-light user d-block"
                  ><i class="mdi mdi-account"></i> Calvin Carlo</small
                >
                <small class="text-light date"
                  ><i class="mdi mdi-calendar-check"></i> 13th August,
                  2019</small
                >
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Schedule a demo with us</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center mt-4">
          <div class="col-lg-7 col-md-10">
            <form>
              <div class="form-group">
                <div class="input-group mb-3">
                  <input
                    name="email"
                    id="email"
                    type="email"
                    class="form-control"
                    placeholder="Your email :"
                    required=""
                    aria-describedby="newssubscribebtn"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary submitBnt"
                      type="submit"
                      id="newssubscribebtn"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!--end form-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Price End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-footer">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->
    <!--Shape End-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
